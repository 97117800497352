// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";

function log(scope, message) {
  if (Belt_Option.getWithDefault(window.pupilfirst.debug, false)) {
    console.log("[" + scope + "] " + message);
    return ;
  }
  
}

function error(scope, message) {
  console.error("[" + scope + "] " + message);
}

export {
  log ,
  error ,
}
/* No side effect */
