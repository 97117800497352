// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Debug from "./Debug.bs.js";
import * as Caml_array from "../../../../node_modules/rescript/lib/es6/caml_array.js";

function log(param) {
  return Debug.log("PSJ", param);
}

function ready(f) {
  if (Document.readyState !== "loading") {
    return Curry._1(f, undefined);
  } else {
    document.addEventListener("DOMContentLoaded", (function (_event) {
            Curry._1(f, undefined);
          }));
    return ;
  }
}

function match(onReadyOpt, path, f) {
  var onReady = onReadyOpt !== undefined ? onReadyOpt : true;
  Debug.log("PSJ", "Try to match " + path);
  var pathFragments = path.split("#");
  if (pathFragments.length !== 2) {
    return Debug.error("PSJ", "Path must be of the format `controller#action` or `module/controller#action`. Received: " + path);
  }
  var metaTag = document.querySelector("meta[name='psj']");
  if (metaTag == null) {
    return ;
  }
  var controller = metaTag.getAttribute("controller");
  var action = metaTag.getAttribute("action");
  if (controller == null) {
    if (action == null) {
      return Debug.error("PSJ", "Meta tag is missing both the controller or action prop.");
    } else {
      return Debug.error("PSJ", "Meta tag is missing the controller prop.");
    }
  } else if (action == null) {
    return Debug.error("PSJ", "Meta tag is missing the action prop.");
  } else if (controller === Caml_array.get(pathFragments, 0) && action === Caml_array.get(pathFragments, 1)) {
    Debug.log("PSJ", "Matched " + path);
    if (onReady) {
      return ready(f);
    } else {
      return Curry._1(f, undefined);
    }
  } else {
    return ;
  }
}

function sanitizePath(path) {
  return path.replace(/^\//, "").replace(/\/$/, "");
}

function matchPaths(onReadyOpt, paths, f) {
  var onReady = onReadyOpt !== undefined ? onReadyOpt : true;
  Debug.log("PSJ", "Try to match paths " + paths.join(", "));
  paths.some(function (path) {
        var pathFragments = path.split("/");
        var currentPathFragments = sanitizePath(window.location.pathname).split("/");
        if (pathFragments.length !== currentPathFragments.length) {
          return false;
        }
        var matched = pathFragments.every(function (fragment, index) {
              if (fragment === "*" || fragment.charAt(0) === ":") {
                return true;
              } else {
                return fragment === Caml_array.get(currentPathFragments, index);
              }
            });
        if (matched) {
          Debug.log("PSJ", "Matched " + path);
          if (onReady) {
            ready(f);
          } else {
            Curry._1(f, undefined);
          }
        }
        return matched;
      });
}

export {
  log ,
  ready ,
  match ,
  sanitizePath ,
  matchPaths ,
}
/* No side effect */
